module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0._druek6nr3m34v7nbm3ewxdh744/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-167628835-2"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-jss@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13_rea_qzpp2qazcfwfwh3vyuai6gck7e/node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13__rnrcfd6wxkjo54taymxfdff7cu/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._5mobmtki336msluocmhnuuob3u/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
